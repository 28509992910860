import { Text, Button, Container, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Progress } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { DownloadIcon } from '@chakra-ui/icons'
import { Spinner } from '@chakra-ui/react'

export const FileList = ({ listMethod, getPublishInfoMethod, downloadMethod, downloadingFiles }) => {

    const [files, setFiles] = useState([]);
    const [publish, setPublish] = useState(null);


    const zeroPadding = (number, len) => {
        return number.toString().padStart(len, '0');
    };

    const formatDate = (isodateText) => {
        if (!isodateText) {
            return '';
        }
        const ts = Date.parse(isodateText);
        const dt = new Date(ts);
        return `${dt.getFullYear()}-${zeroPadding(dt.getMonth() + 1, 2)}-${zeroPadding(dt.getDate(), 2)} ${zeroPadding(dt.getHours(), 2)}:${zeroPadding(dt.getMinutes(), 2)}`;
    };

    useEffect(() => {
        (async () => {
            setFiles(await listMethod());
            setPublish(await getPublishInfoMethod());
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxW='container.lg'>
            <Text>残り認証可能数: <NumericFormat value={publish?.download_auth_limit - publish?.download_auth_count} thousandSeparator={true} displayType="text" /></Text>
            <Text>有効期限: {formatDate(publish?.publish_end_datetime)}</Text>

            <TableContainer width={'auto'} overflowX={'visible'} overflowY={'visible'}>
                <Table variant='simple' >
                    <Thead>
                        <Tr>
                            <Th px={1} pl={3} textAlign={'center'} >ファイル名</Th>
                            <Th px={1} textAlign={'center'} isNumeric>サイズ</Th>
                            <Th px={1} textAlign={'center'} >操作</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {files.map((f) => (
                            <Tr key={f.file_name} transition="0.3s" _hover={{bg: 'green.50'}}>
                                <Td px={1} pl={3} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{f.file_name}</Td>
                                <Td px={2} w='10' isNumeric><NumericFormat value={f.size / 1024} decimalScale={0} thousandSeparator={true} displayType="text" suffix=" KB" /></Td>
                                <Td px={2} w={148} textAlign={'center'}>
                                    <Box h='10' w={146}>
                                        <Button w={146} colorScheme='blue' onClick={() => { downloadMethod(f.file_name); }} disabled={f.file_name in downloadingFiles}>
                                            <Box mr={2} w={7} h={6}>
                                                {f.file_name in downloadingFiles ? <Spinner /> : <DownloadIcon />}
                                            </Box>
                                            ダウンロード
                                        </Button>
                                        {f.file_name in downloadingFiles && <Progress h={1} w={146} value={100 * downloadingFiles[f.file_name] / f.size} />}
                                    </Box>
                                </Td>
                            </Tr>
                        ))}

                    </Tbody>
                </Table>
            </TableContainer>
        </Container>
    );
};