import { Text, Input, InputGroup, InputRightElement, Button, HStack, Container } from '@chakra-ui/react'
import { useState, useRef, useEffect } from 'react';

const PasswordInput = ({ value, onChange, disabled, _ref }) => {
    const [show, setShow] = useState(false)
    const handleToggleShow = () => setShow(!show)


    return (
        <InputGroup size='md'>
            {!disabled &&
                <>
                    <Input
                        value={value}
                        onChange={(e) => onChange(e)}
                        pr='4rem'
                        className={show ? 'unmasked_password' : 'masked_password'}
                        placeholder=''
                        disabled={disabled}
                        autoComplete="off"
                        ref={_ref}
                    />

                    {value.length > 0 &&
                        <InputRightElement width='6rem'>
                            <Button h='1.75rem' size='sm' onClick={handleToggleShow}>
                                {show ? '隠す' : '表示する'}
                            </Button>
                        </InputRightElement>
                    }
                </>
            }
            {disabled &&
                <>
                    <Input
                        pr='4rem'
                        disabled={true}
                        style={{ background: "#eeeeee" }}
                    />
                </>
            }
        </InputGroup>
    )
};

export const AuthForm = ({ onSuccess, onFail, publishKey, authMethod, toast }) => {

    const [password, setPassword] = useState('');
    const passwordRef = useRef(null);

    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        passwordRef.current?.focus();
    }, []);


    const handleClick = (e) => {
        setProcessing(true);
        authMethod(publishKey, password)
            .then((result) => {
                toast({
                    position: 'top-right',
                    description: '認証成功',
                    status: 'info'
                });
                onSuccess(result);
            })
            .catch((result) => {
                //console.log(result.response.data.detail);
                let toastMessage = "認証に失敗しました(" + result.response.status + ")";
                if (result.response.data.detail) {
                    toastMessage = "認証に失敗しました\n(" + result.response.data.detail + ")";
                    if (result.response.data.detail.includes('authentication failed')) {
                        toastMessage = "認証に失敗しました";
                    }
                    if (result.response.data.detail.includes('expired')) {
                        toastMessage = "有効期限切れです";
                    }
                    if (result.response.data.detail.includes('exceeded')) {
                        toastMessage = "利用可能回数を超えました";
                    }
                }
                toast({
                    position: 'top-right',
                    description: toastMessage,
                    status: 'warning'
                });
                onFail();
            })
            .finally(() => {
                setProcessing(false);
            });
    }




    return (
        <>
            <Container>
                <Text m={4} align='left'>ダウンロード用パスワードを入力し、決定を押してください。</Text>
                <HStack>
                    <PasswordInput
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={processing}
                        _ref={passwordRef} />

                    <Button
                        colorScheme={'blue'}
                        width='6rem'
                        onClick={handleClick}
                        isLoading={processing}
                        disabled={processing}>決定</Button>
                </HStack>
            </Container>
        </>
    )
};


