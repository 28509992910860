import "./App.css";
import { AuthForm } from "./AuthForm.js";
import { FileList } from "./FileList.js";
import { useState, useEffect } from "react";
import fileDownload from "js-file-download";
import { useToast } from "@chakra-ui/react";
import { GoodwinTemplate } from "./GoodwinTemplate";
import { PoinsettiaApiClient } from "./PoinsettiaApiClient";
import { ChakraProvider, Text } from "@chakra-ui/react";
import { theme } from "./theme";

export const App = () => {
  const toast = useToast();
  const [mode, setMode] = useState("auth");
  const [api, setApi] = useState("");
  const [publishKey, setPublishKey] = useState("");
  const [token, setToken] = useState("");

  const [downloadingFiles, setDownloadingFiles] = useState({});

  const handleDownload = (fileName) => {
    (async () => {
      setDownloadingFiles((prev) => {
        let next = { ...prev };
        next[fileName] = 0;
        return next;
      });

      let onProgress = (progressEvent) => {
        setDownloadingFiles((prev) => {
          let next = { ...prev };
          next[fileName] = progressEvent.loaded;
          return next;
        });
      };

      let blob = null;
      try {
        let res = await api
          .download(publishKey, fileName, token, onProgress)
          .catch((e) => {
            if (e.response.status === 401) {
              toast({
                position: "top-right",
                description:
                  "ログインが無効です。\n再度、ダウンロード用パスワードを入力してください。",
                status: "error",
              });
              setToken("");
              setMode("auth");
              return;
            }
          });

        blob = new Blob([res.data]);
      } finally {
        setDownloadingFiles((prev) => {
          let next = { ...prev };
          delete next[fileName];
          return next;
        });
      }

      fileDownload(blob, fileName);
    })();
  };

  useEffect(() => {
    let api = new PoinsettiaApiClient();
    api.onNetworkError = showNetworkError;
    setApi(api);

    setPublishKey(window.location.toString().replace(/^.*\//, ""));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authorize = async (publishKey, password) => {
    return await api.authorize(publishKey, password);
  };

  const handleAuthSuccess = (result) => {
    setToken(result.data.access_token);
    setMode("fileList");
  };

  const showNetworkError = (e) => {
    let status = "error";
    let toastMessage =
      "通信に失敗しました。\n申し訳ございませんが、しばらくお待ちいただき、再度お試しください。";
    if ("response" in e) {
      toastMessage =
        "予期せぬエラーが発生しました。\n申し訳ございませんが、しばらくお待ちいただき、再度お試しください。";
    }

    toast({
      position: "top-right",
      description: toastMessage,
      status: status,
    });
  };

  return (
    <ChakraProvider theme={theme}>
      <GoodwinTemplate title="グッドウイン ファイルダウンロード">
        {publishKey && (
          <div className="App">
            {mode === "auth" && (
              <AuthForm
                onSuccess={handleAuthSuccess}
                onFail={() => {}}
                publishKey={publishKey}
                authMethod={authorize}
                toast={toast}
              />
            )}

            {mode === "fileList" && (
              <FileList
                listMethod={() => api.getFiles(publishKey, token)}
                getPublishInfoMethod={() =>
                  api.getPublishInfo(publishKey, token)
                }
                downloadMethod={handleDownload}
                downloadingFiles={downloadingFiles}
                toast={toast}
              />
            )}
          </div>
        )}
        {!publishKey && <Text align="center">URLが不正です</Text>}
      </GoodwinTemplate>
    </ChakraProvider>
  );
};
