import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
    styles: {
        global: {
            "button:not(:disabled):active": {
                transform: 'scale(0.9)'
            },
            "button:disabled": {
                cursor: 'default !important'
            },
        }
    }
});