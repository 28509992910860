import axios from "axios";
export class PoinsettiaApiClient {
    constructor() {
      this.urlBase = process.env.REACT_APP_API_URL;
      this.onNetworkError = null;
    }
  
    async common_invoke_wrapper(func) {
      try {
        let ret = await func();
        return ret;
      } catch (e) {
        if (!('response' in e) || e.response.status >= 500) {
          if (this.onNetworkError) {
            this.onNetworkError(e);
          }
        }
        throw e;
      }
    }
  
    async authorize(publish_key, password) {
      return await this.common_invoke_wrapper(async () => {
        let postBody = {
          publish_key: publish_key,
          password: password
        }
        return await axios.post(this.urlBase + 'auth', postBody);
      });
    }
  
    async getFiles(publish_key, session_token) {
      return await this.common_invoke_wrapper(async () => {
        const res = await axios.get(
          this.urlBase + 'files/' + publish_key,
          { headers: { Authorization: `Bearer ${session_token}` } }
        );
        return res.data.files;
      });
    }
  
    async getPublishInfo(publishKey, session_token) {
      return await this.common_invoke_wrapper(async () => {
        const res = await axios.get(
          this.urlBase + 'publish_info/' + publishKey,
          { headers: { Authorization: `Bearer ${session_token}` } }
        );
        return res.data.publish;
      });
    }
  
    async download(publishKey, fileName, session_token, onProgress) {
      return await this.common_invoke_wrapper(async () => {
        return await axios.get(
          this.urlBase + 'download/' + publishKey + '/' + fileName + '?s=' + new Date().getTime(),
          {
            responseType: 'arraybuffer',
            headers: {
              Authorization: `Bearer ${session_token}`
            },
            onDownloadProgress: onProgress
          }
        );
      });
    }
  
  }
  
  