import './GoodwinTemplate.css';
import GoodwinLogo from './goodwinLogo.svg';
import { Heading } from '@chakra-ui/react'
import { Center } from '@chakra-ui/react'
export const GoodwinTemplate = (props) => {
    return (
        <>
            <div className="whole">
                <header className="header">
                    <Center>
                        <Heading as='h2' size='lg' m='10'>
                            <img src={GoodwinLogo} alt="goodwin" className="header_logo_icon" />{props.title}
                        </Heading>
                    </Center>
                </header>
                {props.children}


                <footer className="footer">
                    <div className="container text-center">
                        <p className="text-muted">© Goodwin Co. Ltd.</p>
                    </div>
                </footer>

            </div>
        </>
    );
};

